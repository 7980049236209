import "./src/styles/global.css";

// Replace 'unload' with 'pagehide' for better bfcache compatibility
export const onClientEntry = () => {
  window.addEventListener("pagehide", () => {
    // Any cleanup code can go here
  });
};

// Wrap sessionStorage operations in try/catch for better error handling
const safeSessionStorage = {
  setItem: (key, value) => {
    try {
      sessionStorage.setItem(key, value);
    } catch (e) {
      console.error("sessionStorage setItem failed:", e);
    }
  },
  getItem: (key) => {
    try {
      return sessionStorage.getItem(key);
    } catch (e) {
      console.error("sessionStorage getItem failed:", e);
      return null;
    }
  },
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  safeSessionStorage.setItem(
    "prevPath",
    prevLocation ? prevLocation.pathname : null
  );
};

// Maintain existing scroll behavior
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (window.history) {
    window.history.scrollRestoration = "manual";
  }
  const currentPosition = getSavedScrollPosition(location, location.key);
  let timeout = 100;
  if (currentPosition && currentPosition.length > 1 && currentPosition[1]) {
    const y = currentPosition[1];
    if (y > 2 * window.innerHeight) {
      timeout = 750;
    }
  }
  setTimeout(() => {
    if (window.scrollTo) {
      window.scrollTo(...(currentPosition || [0, 0]));
    }
  }, timeout);
  return false;
};

// Add bfcache monitoring
if (typeof document !== "undefined") {
  document.addEventListener("pageshow", (event) => {
    if (event.persisted) {
      console.log("This page was restored from the bfcache.");
    }
  });
}

// Handle service worker updates
export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `This application has been updated. ` +
      `Reload to display the latest version?`
  );
  if (answer === true) {
    window.location.reload();
  }
};
